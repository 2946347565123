/* @font-face {
  font-family: "Inter UI";
  src: url(./fonts/Inter-Regular.woff2);
  font-weight: 400;
} */
@font-face {
  font-family: "Inter UI";
  src: url(./fonts/Inter-Medium.woff2);
  font-weight: 500;
}
@font-face {
  font-family: "Inter UI";
  src: url(./fonts/Inter-SemiBold.woff2);
  font-weight: 600;
}
@font-face {
  font-family: "Inter UI";
  src: url(./fonts/Inter-Bold.woff2);
  font-weight: 700;
}
/* @font-face {
  font-family: "Inter UI";
  src: url(./fonts/Inter-ExtraBold.woff2);
  font-weight: 800;
}
@font-face {
  font-family: "Inter UI";
  src: url(./fonts/Inter-Black.woff2);
  font-weight: 900;
} */

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}
body,
button,
input,
textarea {
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
body {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  font-weight: 500;
  font-display: fallback;
  font-size: 1em;
  background: hsl(228, 33%, 96%);
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

@media (max-width: 640px) {
  body {
    font-size: 90%;
  }
}

a {
  /* text-decoration: none; */
  color: inherit;
}

.recharts-label-list text {
  font-size: 0.8em;
  fill: black;
  stroke: none;
}

.recharts-legend-item-text {
  font-size: 0.8em;
}

/* React Tabs */

@media (max-width: 640px) {
  .react-tabs {
    font-size: 0.9em;
  }
}

.react-tabs__tab-list {
  background: hsl(228, 33%, 92%) !important;
  padding: 1em 1em 0 !important;
  border-bottom: none !important;
  /* border-bottom: 0.4em solid hsl(228, 33%, 96%); */
  margin: 0 0 1em !important;
  box-shadow: inset 0px -8px 15px -10px #b2b4c140;
  position: sticky;
  top: 3em;
  z-index: 1;
}

.react-tabs__tab {
  font-size: 1em;
  color: #81849e;
  padding: 0.5em 1.2em;
  cursor: pointer;
  border: none;
}

.react-tabs__tab--selected {
  background: hsl(228, 33%, 96%) !important;
  /* border-color: black; */
  color: black;
  border-radius: 0.3em 0.3em 0 0;
  box-shadow: 0px -5px 5px #b2b4c115;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  width: 100%;
}
